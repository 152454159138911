// import $ from 'jquery'

// $(document).ready( initHero )
// if (window.acf) {
//   window.acf.addAction('render_block_preview/type=hero', initHero)
// }

// function initHero(){
//   if ($('.hero.is-style-hero-var-2').length ) {
//     $('.header').addClass('header--transparent');
//   }
//   if ($('.hero.is-style-hero-var-1').length) {
//     $('.header').addClass('header--transparent-dark');
//   }
// }
